import React, { useState, useEffect, Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash'
import firebase from 'firebase/compat/app'
import { getFunctions, httpsCallable } from 'firebase/functions'

import LoaderWrapper from '@invie/common/dist/components/loader-wrapper'
import Head from '@invie/common/dist/components/head'
import PhoneInput from '@invie/common/dist/components/phone-input'
import Emoji from '@invie/common/dist/components/emoji'

import { Container, Row, Col } from '@invie/common/dist/elements/layout'
import {
  Background,
  Box,
  Button,
  Title,
  Subtitle,
  Text,
  Input,
  Badge,
  EmojiWrapper,
  AdTitle
} from '@invie/common/dist/elements'

import { useAuth } from '@invie/common/dist/utils/use-auth'
import { useStateValue } from '@invie/common/dist/utils/use-state'
import { getAd, getEvent } from '@invie/common/dist/utils/firebase'
import { getType } from '@invie/common/dist/utils/types'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const withRouter = (Component: any) => {
  function ComponentWithRouterProp(props: any) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return <Component {...props} router={{ location, navigate, params }} />
  }

  return ComponentWithRouterProp
}

const App: React.FC = (props: any) => {
  const auth = useAuth()
  const [{ publicEvent, event, ad }, dispatch] = useStateValue()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [name, setName] = useState('')
  //hide last 4 digit flow for make showName boolean to true
  const [showName, setShowName] = useState(true)
  const [number, setNumber] = useState('')
  const eventId = props.router.params.eventId

  useEffect(() => {
    if (!auth.initialising && !auth.user) {
      auth.signInAnonymously()
    }
  }, [auth])

  useEffect(() => {
    const functions = getFunctions()
    const GetPublicEvent = httpsCallable(functions, 'getEvent')
    // indexedDB.deleteDatabase('firebase-heartbeat-database')
    // indexedDB.deleteDatabase('firebaseLocalStorageDb')
    GetPublicEvent({ eventId: eventId }).then((result: any) => {
      if (!result.data) return
      result.data.type = getType(result.data.type)
      dispatch({
        type: 'REQUEST_PUBLIC_EVENT_SUCCESS',
        event: result.data
      })
      isEmpty(ad) &&
        getAd(result.data.type.type).then((data: any) => {
          dispatch({
            type: 'REQUEST_AD_SUCCESS',
            ad: data
          })
        })
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (number.length === 4) {
      auth.setNumber(number)
    }
  }, [number])

  useEffect(() => {
    getEvent(eventId)
      .then((data: any) => {
        if (data) {
          dispatch({
            type: 'REQUEST_EVENT_SUCCESS',
            event: data
          })
          setLoading(false)
          // if (auth.userData.number) {
          props.router.navigate('/event')
          // } else if (auth.userData.number) {
          //   setShowName(true)
          //   setLoading(false)
          //   setError(false)
          // }
        }
      })
      .catch((err) => {
        if (number && auth.userData.number) {
          setError(true)
        }
      })
  }, [auth.user, auth.userData])

  const goToEvent = () => {
    if (name) {
      //hide last 4 digit flow
      auth.setNumber(Math.floor(1000 + Math.random() * 9000).toString())
      auth.setName(name)
      setLoading(true)
      // props.router.navigate('/event')
    }
  }

  if (loading || auth.initialising) return <LoaderWrapper />

  return (
    <Background color='primary' adColor={ad && ad.background}>
      {/* <Head event={publicEvent} /> */}
      <Container fullHeight padding center>
        <Box style={{ marginTop: '3rem', paddingTop: '3rem' }}>
          <div
            style={{
              position: 'absolute',
              top: '-80px',
              left: 0,
              right: 0,
              display: 'flex'
            }}
          >
            <EmojiWrapper>
              {/* <Emoji emoji={publicEvent.type.emoji} /> */}
              {publicEvent?.type?.image ? (
                <img src={publicEvent?.type?.image} style={{ width: '60px' }} />
              ) : null}
            </EmojiWrapper>
          </div>
          <Row center='xs'>
            <Col xs={9}>
              <Text color='primary' align='center' margin={false}>
                <FormattedMessage id='descriptions.invitedFor' />
              </Text>
              <Title color='primary' align='center'>
                {publicEvent.title}
              </Title>
            </Col>
            {!showName && (
              <React.Fragment>
                <Col xs={9}>
                  <Subtitle
                    align='center'
                    color='primary'
                    style={{ marginBottom: '1rem' }}
                  >
                    <FormattedMessage id='descriptions.phoneNumber' />
                  </Subtitle>
                  {error && (
                    <Badge type='alert'>
                      <FormattedMessage id='errors.invalidCode' />
                    </Badge>
                  )}
                </Col>
                <Col xs={12}>
                  <PhoneInput
                    amount={4}
                    handleOutput={(number) => setNumber(number)}
                  />
                </Col>
              </React.Fragment>
            )}
            {showName ? (
              <React.Fragment>
                {event?.organizer ? (
                  <Col xs={9}>
                    <Subtitle
                      align='center'
                      color='primary'
                      style={{ marginBottom: '1rem' }}
                    >
                      <FormattedMessage id='descriptions.organizedBy' />:{' '}
                      {event?.organizer}
                    </Subtitle>
                  </Col>
                ) : (
                  <></>
                )}
                <Col xs={9}>
                  <Subtitle
                    align='center'
                    color='primary'
                    style={{ marginBottom: '1rem' }}
                  >
                    <FormattedMessage id='descriptions.name' />
                  </Subtitle>
                </Col>
                <Col xs={12}>
                  <Input
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                  />
                  <Button
                    color='primary'
                    onClick={() => goToEvent()}
                    style={{ marginTop: '1rem', float: 'right' }}
                  >
                    <FormattedMessage id='buttons.next' />
                  </Button>
                </Col>
              </React.Fragment>
            ) : null}
          </Row>
        </Box>
        {ad ? (
          <Row center='xs' padding>
            <Col xs={9}>
              <AdTitle align='center' color={ad.color} large>
                {ad.text}
              </AdTitle>
            </Col>
            <Col xs={6}>
              <img src={ad.imageUrl} width='100%' />
            </Col>
          </Row>
        ) : null}
      </Container>
    </Background>
  )
}

export default withRouter(App)
