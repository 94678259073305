var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import RSelect from 'react-select';
import { shadows } from '../utils/theme';
var Select = styled(RSelect)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: 700;\n  font-size: 1.2rem;\n  width: 100%;\n  margin: 3px;\n  border-radius: 0.5rem;\n  border: none;\n  text-align: center;\n  box-shadow: ", ";\n\n  :active,\n  :focus {\n    outline: none;\n  }\n"], ["\n  font-weight: 700;\n  font-size: 1.2rem;\n  width: 100%;\n  margin: 3px;\n  border-radius: 0.5rem;\n  border: none;\n  text-align: center;\n  box-shadow: ", ";\n\n  :active,\n  :focus {\n    outline: none;\n  }\n"])), shadows.input);
export default Select;
var templateObject_1;
