var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { createGlobalStyle } from 'styled-components';
import reboot from 'styled-reboot';
import styledTheme from 'styled-theming';
var options = {
    fontFamilyBase: '"Muli", sans-serif',
    bodyColor: '#25303E'
};
var theme = {
    mode: 'light',
    borderRadius: 'default',
    flexboxgrid: {
        gridSize: 12,
        gutterWidth: 1,
        outerMargin: 1,
        mediaQuery: 'only screen',
        container: {
            sm: 46,
            md: 61,
            lg: 76
        },
        breakpoints: {
            xs: 0,
            sm: 48,
            md: 64,
            lg: 75
        }
    }
};
var base = {
    fontSize: '1rem',
    lineHeight: '1.5rem'
};
var grays = {
    100: '#F8F9FA',
    200: '#E9ECEF',
    300: '#DEE2E6',
    400: '#CED4DA',
    500: '#ADB5BD',
    600: '#6C757D',
    700: '#495057',
    800: '#343A40',
    900: '#212529'
};
var colors = {
    primary: '#24A2EF',
    secondary: '#E8EBED',
    success: '#219A63',
    danger: '#FFFFFF',
    warning: '#FFC107',
    light: '#FFFFFF',
    dark: '#4E4E4E'
};
var states = {
    default: '#FFFFFF',
    notice: '#0CD420',
    alert: '#BB2035'
};
var fontColor = styledTheme.variants('mode', 'color', {
    primary: { light: colors.primary },
    dark: { light: colors.dark },
    light: { light: colors.light }
});
var alertColor = styledTheme.variants('mode', 'type', {
    default: { light: colors.dark },
    notice: { light: colors.success },
    alert: { light: colors.danger }
});
var alertBackgroundColor = styledTheme.variants('mode', 'type', {
    default: { light: states.default },
    notice: { light: states.notice },
    alert: { light: states.alert }
});
var backgroundColor = styledTheme.variants('mode', 'color', {
    default: { light: colors.primary },
    primary: { light: colors.primary },
    secondary: { light: colors.secondary },
    success: { light: colors.success },
    danger: { light: colors.danger },
    warning: { light: colors.warning },
    light: { light: colors.light },
    dark: { light: colors.dark }
});
var borderColor = backgroundColor;
var borderRadius = styledTheme('borderRadius', {
    default: '50rem',
    large: '0.5rem',
    small: '0.15rem'
});
var transitions = {
    button: 'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
};
var shadows = {
    default: '0px 2px 2px rgba(0, 0, 0, 0.15)',
    input: '0px 3px 14px rgba(0, 0, 0, 0.25)'
};
var button = {
    paddingY: '0.85rem',
    paddingX: '1.2rem'
};
var GlobalStyle = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  @import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700,800&display=swap');\n  body, html {\n    height: 100%;\n    background-color: ", ";\n  }\n  body {\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n  }\n  #root {\n    display: flex;\n    min-height: 100%;\n    width: 100%;\n  }\n"], ["\n  ", "\n  @import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700,800&display=swap');\n  body, html {\n    height: 100%;\n    background-color: ", ";\n  }\n  body {\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n  }\n  #root {\n    display: flex;\n    min-height: 100%;\n    width: 100%;\n  }\n"])), reboot, colors.primary);
export { theme, base, grays, colors, fontColor, alertColor, alertBackgroundColor, backgroundColor, borderColor, borderRadius, transitions, shadows, button };
export default GlobalStyle;
var templateObject_1;
