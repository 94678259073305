import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import firebase from 'firebase/compat/app'
import moment from 'moment'
import { isEmpty, set } from 'lodash'

import LoaderWrapper from '@invie/common/dist/components/loader-wrapper'
import Emoji from '@invie/common/dist/components/emoji'
import Date from '@invie/common/dist/components/date'
import Map from '@invie/common/dist/components/map'
import circle from '@invie/common/assets/images/circle.png'
import enterIcon from '@invie/common/assets/images/enter.png'
import exitIcon from '@invie/common/assets/images/exit.png'
import './app.css'

import { Container, Row, Col } from '@invie/common/dist/elements/layout'
import {
  Background,
  Header,
  Box,
  Title,
  Subtitle,
  Text,
  Button,
  Input
} from '@invie/common/dist/elements'

import { useAuth } from '@invie/common/dist/utils/use-auth'
import { useStateValue } from '@invie/common/dist/utils/use-state'
import { getEvent } from '@invie/common/dist/utils/firebase'
import { getType } from '@invie/common/dist/utils/types'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CiLocationOn } from 'react-icons/ci'
import { useMedia } from '../utils/use-media'
import axios from 'axios'

const withRouter = (Component: any) => {
  function ComponentWithRouterProp(props: any) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return <Component {...props} router={{ location, navigate, params }} />
  }

  return ComponentWithRouterProp
}

const Event: React.FC = (props: any) => {
  const auth = useAuth()
  const [{ event, eventId }, dispatch] = useStateValue()
  const isMobile = useMedia({ query: '(max-width: 767px)' })

  const type = event && getType(event.type)
  const emoji = type ? type.image : ''
  const [name, setName] = useState('')
  const [email, setEmail] = useState<string>('')
  const [img, setImg] = useState<string>('')

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowDimensions({
  //       width: window.innerWidth,
  //       height: window.innerHeight
  //     })
  //   }

  //   window.addEventListener('resize', handleResize)

  //   return () => window.removeEventListener('resize', handleResize)
  // }, [])

  // useEffect(() => {
  //   const resizeImage = async () => {
  //     try {
  //       const image = new Image()
  //       image.crossOrigin = 'anonymous'

  //       // Load the image from the URL
  //       image.src = event.template.img

  //       // Wait for the image to load
  //       await new Promise((resolve) => (image.onload = resolve))

  //       // Check if the image is not null before accessing properties
  //       if (image) {
  //         // Calculate the new dimensions
  //         const aspectRatio = image.width / image.height
  //         const newWidth = Math.min(image.width, windowDimensions.width)
  //         const newHeight = Math.min(
  //           newWidth / aspectRatio,
  //           windowDimensions.height
  //         )

  //         // Create a canvas element
  //         const canvas = document.createElement('canvas')
  //         canvas.width = newWidth
  //         canvas.height = newHeight

  //         // Resize the image on the canvas
  //         const context = canvas.getContext('2d')
  //         context?.drawImage(image, 0, 0, newWidth, newHeight)

  //         // Get the resized image as a data URL
  //         const resizedUrl = canvas.toDataURL('image/jpeg') // Adjust format if needed

  //         // Update the state with the resized image URL
  //         // setResizedImageUrl(resizedUrl);
  //         setImg(resizedUrl)
  //       }
  //     } catch (error) {
  //       console.error('Error resizing image:', error)
  //     }
  //   }

  //   // Resize the image when the component mounts or when the URL changes
  //   if (event) {
  //     resizeImage()
  //   }
  // }, [event, windowDimensions])

  useEffect(() => {
    isEmpty(event) &&
      getEvent(eventId).then((data: any) => {
        if (data) {
          setImg(
            isMobile
              ? data?.template?.img
              : data?.template?.web_img ?? data?.template?.img
          )
          dispatch({
            type: 'REQUEST_EVENT_SUCCESS',
            event: data
          })
        }
      })
  }, [eventId])

  const setPresence = (presence: boolean) => {
    if (!name.length) {
      return alert('Vul je naam in')
    }
    if (name.trim().length < 3) {
      alert('De naam moet minimaal 3 tekens lang zijn')
      return
    }
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!emailRegex.test(email.trim())) {
      alert('Voer een geldig e-mailadres in')
      return
    }

    // auth.setNumber(Math.floor(1000 + Math.random() * 9000).toString())
    auth.setNumber(email.toLowerCase())
    auth.setName(name)

    firebase
      .firestore()
      .collection('events')
      .doc(event.eventId)
      .collection('guests')
      // .doc(auth.userData.number)
      .doc(email.toLowerCase())
      .set(
        { name: name, present: presence, email: email?.toLowerCase() },
        { merge: true }
      )
      .then(async () => {
        if (presence && email && event) {
          try {
            const response = await sendInvitationEmail()

            console.log('inviatrion response>>', response)
          } catch (error) {
            console.log('invitation error>>', error)
          }
        }

        presence
          ? props.router.navigate('/accepted')
          : props.router.navigate('/declined')
      })
  }

  const sendInvitationEmail = () =>
    axios
      .post(
        'https://us-central1-invie-1564488892431.cloudfunctions.net/sendEmail',
        {
          email,
          eventDetail: event
        }
      )
      .then((response) => response.data)

  const imageStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
    resizeMode: 'contain'
  }
  const circleStyle = {
    width: '16px'
  }
  const subTitleStyle = {
    paddingLeft: '20px',
    paddingTop: '0px',
    paddingBottom: '20px'
  }
  const textStyle = {
    fontSize: '14px',
    fontWeight: 'Bold'
  }
  const enterBtnStyle = {
    color: '#ffffff',
    borderRadius: '4px',
    backgroundColor: '#24A2EF',
    // width: '50%',
    marginBottom: '10px'
  }
  const exitBtnStyle = {
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    // width: '50%',
    marginBottom: '10px',
    color: '#24A2EF'
  }
  const containerStyle = {
    // backgroundColor: '#ffffff',
    borderRadius: '10px',
    padding: '20px',
    width: '50%'
  }

  if (isEmpty(event)) return <LoaderWrapper />

  const bgUrl = isMobile
    ? event?.template?.img
    : event?.template?.web_img ?? event?.template?.img

  return (
    <Background
      color='primary'
      // style={{ position: 'relative' }}
      // className='bg-body'
      style={{
        // backgroundImage: `url(${img})`,
        backgroundImage: `url(${bgUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed'
      }}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          backgroundColor:
            event.template.isOverlay === true
              ? 'rgba(0,0,0,0.5)'
              : 'transparent',
          padding: '40px',
          position: 'relative',
          zIndex: 2
        }}
      >
        <div className='event-container'>
          <Header>
            <Col xs={12} className='top-emoji'>
              {/* <Emoji emoji={emoji} /> */}
              {emoji ? <img src={emoji} style={{ width: '60px' }} /> : null}
            </Col>
            {event.eventImage !== '' && (
              <Row middle='xs'>
                <Col xs={12}>
                  <img src={event.eventImage} style={imageStyle} />
                </Col>
              </Row>
            )}
            <Row middle='xs' style={{ marginTop: '10px' }}>
              <Col xs={12}>
                <Title style={{ color: event.template.textColor }}>
                  {event.title}
                </Title>
              </Col>

              {event?.organizer ? (
                <Col xs={9}>
                  <Subtitle
                    style={{
                      marginBottom: '1rem',
                      fontWeight: '500',
                      color: event.template.textColor
                    }}
                  >
                    <FormattedMessage id='descriptions.organizedBy' />:{' '}
                    {event?.organizer}
                  </Subtitle>
                </Col>
              ) : (
                <></>
              )}

              {/* <Col xs={2} className='left-emoji'>
              <Emoji emoji={emoji} />
            </Col> */}
              <Col xs={12}>
                <Text style={{ color: event.template.textColor }}>
                  {event.message}
                </Text>
              </Col>
            </Row>
          </Header>
          {/* <Row>
          <Col xs={12}>
            <Text>{event.message}</Text>
          </Col>
        </Row> */}
          <Row>
            <Col xs={12}>
              {moment.unix(event.startTime.seconds).format('DD MMM, YYYY') ===
                moment.unix(event.endTime.seconds).format('DD MMM, YYYY') && (
                <Row>
                  <Col xs={12}>
                    <Text
                      style={{
                        fontSize: '14px',
                        color: event.template.textColor
                      }}
                    >
                      <img src={circle} style={circleStyle} />
                      &nbsp;&nbsp;Begintijd
                    </Text>
                    <Subtitle
                      style={{
                        paddingLeft: '20px',
                        paddingTop: '0px',
                        paddingBottom: '20px',
                        color: event.template.textColor
                      }}
                    >
                      {moment
                        .unix(event.startTime.seconds)
                        .format('DD MMM, YYYY')}
                      &nbsp;-&nbsp;
                      {moment.unix(event.startTime.seconds).format('h:mm:ss A')}
                      &nbsp;-&nbsp;
                      {moment.unix(event.endTime.seconds).format('h:mm:ss A')}
                    </Subtitle>
                  </Col>
                </Row>
              )}
              {moment.unix(event.startTime.seconds).format('DD MMM, YYYY') !==
                moment.unix(event.endTime.seconds).format('DD MMM, YYYY') && (
                <>
                  <Row>
                    <Col xs={12}>
                      <Text
                        style={{
                          fontSize: '14px',
                          color: event.template.textColor
                        }}
                      >
                        <img src={circle} style={circleStyle} />
                        &nbsp;&nbsp;Begintijd
                      </Text>
                      <Subtitle style={subTitleStyle}>
                        {moment
                          .unix(event.startTime.seconds)
                          .format('DD MMM, YYYY')}
                        &nbsp;-&nbsp;
                        {moment
                          .unix(event.startTime.seconds)
                          .format('h:mm:ss A')}
                      </Subtitle>
                      <Text style={{ fontSize: '14px' }}>
                        <img src={circle} style={circleStyle} />
                        &nbsp;&nbsp;Eindtijd
                      </Text>
                      <Subtitle style={subTitleStyle}>
                        {moment
                          .unix(event.endTime.seconds)
                          .format('DD MMM, YYYY')}
                        &nbsp;-&nbsp;
                        {moment.unix(event.endTime.seconds).format('h:mm:ss A')}
                      </Subtitle>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Row style={{ marginBottom: 15 }}>
                {/* <Text
                  style={{
                    fontSize: '14px',
                    color: event.template.textColor,
                    fontWeight: 'Bold'
                  }}
                >
                  &nbsp;&nbsp;Locatie
                </Text> */}
                {/* <Box> */}
                <CiLocationOn
                  size={20}
                  color={event?.template?.textColor}
                  style={{ marginRight: 10 }}
                />
                <Subtitle style={{ color: event?.template?.textColor }}>
                  {event.address.streetName + ' '}
                  {event.address.streetNumber + ', '}
                  {event.address.city}
                  {/* {event.address.zip} */}
                </Subtitle>
              </Row>

              {/* <Date
                startTime={moment.unix(event.startTime.seconds).format()}
                endTime={
                  event.endTime && moment.unix(event.endTime.seconds).format()
                }
              /> */}
              {/* </Box> */}
              <Box padding={false} style={{ overflow: 'hidden' }}>
                <Row middle='xs'>
                  <Col xs={12}>
                    <Map
                      coordinates={[
                        event.location.longitude,
                        event.location.latitude
                      ]}
                      zoom={15}
                    />
                  </Col>
                  {/* <Col xs={8}>
                  <Subtitle>
                    {event.address.streetName} {event.address.streetNumber}
                  </Subtitle>
                  <Text margin={false}>{event.address.city}</Text>
                </Col> */}
                </Row>
              </Box>
            </Col>
          </Row>

          <Col xs={9} style={{ marginTop: 50 }}>
            <Subtitle
              // align='center'
              color='primary'
              style={{ marginBottom: '1rem' }}
            >
              <FormattedMessage id='descriptions.name' />
            </Subtitle>
          </Col>

          <Col xs={12}>
            <Input
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
            {/* <Button
              color='primary'
              onClick={() => console.log('There')}
              style={{ marginTop: '1rem', float: 'right' }}
            >
              <FormattedMessage id='buttons.next' />
            </Button> */}
          </Col>

          <Col xs={9} style={{ marginTop: 30 }}>
            <Subtitle
              // align='center'
              color='primary'
              style={{ marginBottom: '1rem' }}
            >
              <FormattedMessage id='descriptions.fillYourEmail' />
            </Subtitle>
          </Col>

          <Col xs={12}>
            <Input
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
          </Col>

          <Row style={{ marginTop: '30px', textAlign: 'center' }}>
            <Col xs={12} md={6}>
              <Col xs={12} md={12} className='enter-btn btn-col'>
                <Button style={enterBtnStyle} onClick={() => setPresence(true)}>
                  <img src={enterIcon} style={circleStyle} />
                  &nbsp;
                  <FormattedMessage id='buttons.accept' />
                </Button>
              </Col>
            </Col>
            <Col xs={12} md={6}>
              <Col xs={12} md={12} className='btn-col'>
                <Button style={exitBtnStyle} onClick={() => setPresence(false)}>
                  <img src={exitIcon} style={circleStyle} />
                  &nbsp;
                  <FormattedMessage id='buttons.decline' />
                </Button>
              </Col>
            </Col>
          </Row>
        </div>
      </div>
      {/* <FixedFooter>
        <Row>
          <Col xs={6}>
            <Button color='success' block onClick={() => setPresence(true)}>
              <FormattedMessage id='buttons.accept' />
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              color='light'
              outline
              block
              onClick={() => setPresence(false)}
            >
              <FormattedMessage id='buttons.decline' />
            </Button>
          </Col>
        </Row>
      </FixedFooter> */}
    </Background>
  )
}

export default withRouter(Event)
