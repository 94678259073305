import { createRoot } from 'react-dom/client'
import createRoutes from './utils/routes'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import * as serviceWorker from './serviceWorker'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getApp } from 'firebase/app'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

firebase.initializeApp({
  apiKey: 'AIzaSyD7F6NOIT1fscfDUiJB8bCRVkfJWSenc8k',
  authDomain: 'invie-1564488892431.firebaseapp.com',
  databaseURL: 'https://invie-1564488892431.firebaseio.com',
  projectId: 'invie-1564488892431',
  storageBucket: 'invie-1564488892431.appspot.com',
  messagingSenderId: '643255844859',
  appId: '1:643255844859:web:675f16de7d9c0e91'
})

// const auth = getAuth()
// connectAuthEmulator(auth, 'http://localhost:9099')
// const db = getFirestore()
// connectFirestoreEmulator(db, 'localhost', 8089)
// const functions = getFunctions(getApp())
// connectFunctionsEmulator(functions, 'localhost', 5003)

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(createRoutes())

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
