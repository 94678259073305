var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import InputBox from './input-box';
var inputRegExp = /^[0-9]$/;
var PhoneInput = /** @class */ (function (_super) {
    __extends(PhoneInput, _super);
    function PhoneInput(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { characterArray: Array(4).fill(null) };
        _this.inputElements = {};
        return _this;
    }
    PhoneInput.prototype.componentDidMount = function () {
        this.inputElements.input0.select();
    };
    PhoneInput.prototype.shouldComponentUpdate = function (nextProps) {
        if (this.props.amount !== nextProps.amount ||
            inputRegExp !== nextProps.inputRegExp) {
            return true;
        }
        return false;
    };
    PhoneInput.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        if (this.props.amount !== nextProps.amount) {
            this.setState({ characterArray: Array(nextProps.amount).fill(null) });
            this.props.handleOutput('');
        }
    };
    PhoneInput.prototype.handleKeyDown = function (_a) {
        var target = _a.target, key = _a.key;
        if (key === 'Backspace') {
            if (target.value === '' && target.previousElementSibling !== null) {
                target.previousElementSibling.value = '';
                this.focusPrevChar(target);
            }
            else {
                target.value = '';
            }
            this.setModuleOutput();
        }
        else if (key === 'ArrowLeft') {
            this.focusPrevChar(target);
        }
        else if (key === 'ArrowRight') {
            this.focusNextChar(target);
        }
    };
    PhoneInput.prototype.handleFocus = function (_a) {
        var target = _a.target;
        var el = target;
        setTimeout(function () {
            el.select();
        }, 0);
    };
    PhoneInput.prototype.focusPrevChar = function (target) {
        if (target.previousElementSibling !== null) {
            target.previousElementSibling.focus();
        }
    };
    PhoneInput.prototype.focusNextChar = function (target) {
        if (target.nextElementSibling !== null) {
            target.nextElementSibling.focus();
        }
    };
    PhoneInput.prototype.setModuleOutput = function () {
        var _this = this;
        this.setState(function (prevState) {
            var updatedCharacters = prevState.characterArray.map(function (character, number) {
                return _this.inputElements['input' + number].value;
            });
            return { characterArray: updatedCharacters };
        }, function () { return _this.props.handleOutput(_this.state.characterArray.join('')); });
    };
    PhoneInput.prototype.handleChange = function (_a) {
        var target = _a.target;
        if (target.value.match(inputRegExp)) {
            this.focusNextChar(target);
            this.setModuleOutput();
        }
        else {
            target.value = this.state.characterArray[target.name.replace('input', '')];
        }
    };
    PhoneInput.prototype.renderItems = function () {
        var _this = this;
        var items = [];
        for (var i = 0; i < this.props.amount; i++) {
            items.push(React.createElement(InputBox, { type: 'number', key: i, handleKeyDown: function (e) { return _this.handleKeyDown(e); }, handleFocus: function (e) { return _this.handleFocus(e); }, handleChange: function (e) { return _this.handleChange(e); }, name: 'input' + i, inputRef: function (el) {
                    if (!el)
                        return;
                    _this.inputElements[el.name] = el;
                } }));
        }
        return items;
    };
    PhoneInput.prototype.render = function () {
        return React.createElement("div", { style: { display: 'flex' } }, this.renderItems());
    };
    return PhoneInput;
}(React.Component));
export default PhoneInput;
