import React from 'react';
import { Row, Col } from '../elements/layout';
import { AdBox as AdBoxContainer, AdTitle, Button } from '../elements';
var AdBox = function (props) { return (React.createElement(AdBoxContainer, { background: props.background, color: props.color, onClick: props.onClick },
    React.createElement(Row, { style: { paddingBottom: '0.5rem' } },
        React.createElement(Col, { xs: 7 },
            React.createElement(AdTitle, null, props.text)),
        React.createElement(Col, { xs: 5 },
            React.createElement("img", { src: props.imageUrl, width: '100%', alt: 'logo' }))),
    React.createElement(Button, { color: props.color, style: { padding: '0.5rem 2rem' } }, props.buttonText))); };
export default AdBox;
