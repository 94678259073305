var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { fontColor } from '../utils/theme';
var Text = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\ncolor: ", ";\ntext-align: ", ";\n  ", "\n"], ["\ncolor: ", ";\ntext-align: ", ";\n  ", "\n"])), function (props) { return fontColor(props); }, function (props) { return props.align; }, function (props) {
    return props.margin === false &&
        "\n    margin: 0;\n  ";
});
export default Text;
var templateObject_1;
