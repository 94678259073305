var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from 'styled-components';
import { darken, transitions } from 'polished';
import invert from 'invert-color';
import { base, colors, backgroundColor, borderColor, borderRadius, transitions as themeTransitions, shadows, button } from '../utils/theme';
var StyledButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  display: ", ";\n  width: ", ";\n  cursor: pointer;\n  color: ", ";\n  background-color: ", ";\n  box-shadow: ", ";\n  border: 2px solid ", ";\n  border-radius: ", ";\n  padding: ", " ", ";\n  font-size: ", ";\n  font-weight: 800;\n  line-height: ", ";\n  ", ";\n  opacity: ", ";\n  pointer-events: ", ";\n  outline: none;\n  text-align: center;\n  vertical-align: middle;\n  user-select: none;\n  appearance: none;\n  align-self: center;\n\n  :hover {\n    color: ", ";\n    text-decoration: none;\n    background-color: ", ";\n    border-color: ", ";\n  }\n\n  :active,\n  :focus {\n    outline: none;\n  }\n"], ["\n  display: inline-block;\n  display: ", ";\n  width: ", ";\n  cursor: pointer;\n  color: ", ";\n  background-color: ", ";\n  box-shadow: ", ";\n  border: 2px solid ", ";\n  border-radius: ", ";\n  padding: ", " ", ";\n  font-size: ", ";\n  font-weight: 800;\n  line-height: ", ";\n  ", ";\n  opacity: ", ";\n  pointer-events: ", ";\n  outline: none;\n  text-align: center;\n  vertical-align: middle;\n  user-select: none;\n  appearance: none;\n  align-self: center;\n\n  :hover {\n    color: ", ";\n    text-decoration: none;\n    background-color: ", ";\n    border-color: ", ";\n  }\n\n  :active,\n  :focus {\n    outline: none;\n  }\n"])), function (props) { return (props.block ? 'block' : 'inline-block'); }, function (props) { return props.block && '100%'; }, function (props) {
    return props.outline
        ? backgroundColor
        : invert(backgroundColor(props), {
            black: colors.dark,
            white: colors.light,
            threshold: 0.35
        });
}, function (props) {
    return props.outline ? 'transparent' : backgroundColor;
}, shadows.default, borderColor, borderRadius, button.paddingY, button.paddingX, base.fontSize, base.lineHeight, transitions(themeTransitions.button), function (props) { return props.disabled && 0.8; }, function (props) { return props.disabled && 'none'; }, function (props) {
    return invert(backgroundColor(props), {
        black: colors.dark,
        white: colors.light,
        threshold: 0.35
    });
}, function (props) {
    return darken(0.05, backgroundColor(props));
}, function (props) {
    return darken(0.05, borderColor(props));
});
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Button.prototype.render = function () {
        return React.createElement(StyledButton, __assign({}, this.props));
    };
    Button.defaultProps = {
        color: 'default',
        borderRadius: 'default'
    };
    return Button;
}(React.Component));
export default Button;
var templateObject_1;
