var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from 'styled-components';
import invert from 'invert-color';
import { colors, alertBackgroundColor, shadows, borderRadius } from '../utils/theme';
var StyledBadge = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  box-shadow: ", ";\n  border-radius: ", ";\n  margin-bottom: 0.5rem;\n  padding: 0.5rem 2rem;\n  text-align: center;\n  font-weight: 700;\n"], ["\n  background-color: ", ";\n  color: ", ";\n  box-shadow: ", ";\n  border-radius: ", ";\n  margin-bottom: 0.5rem;\n  padding: 0.5rem 2rem;\n  text-align: center;\n  font-weight: 700;\n"])), alertBackgroundColor, function (props) {
    return invert(alertBackgroundColor(props), {
        black: colors.dark,
        white: colors.light,
        threshold: 0.35
    });
}, shadows.default, borderRadius);
var Badge = /** @class */ (function (_super) {
    __extends(Badge, _super);
    function Badge() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Badge.prototype.render = function () {
        return React.createElement(StyledBadge, __assign({}, this.props));
    };
    Badge.defaultProps = {
        type: 'default'
    };
    return Badge;
}(React.Component));
export default Badge;
var templateObject_1;
