var types = [
    {
        type: 'birthday',
        color: '#219A63',
        name: 'Verjaardag',
        // emoji: '🎉'
        image: require('../../assets/images/birthday.png')
    },
    {
        type: 'dinner',
        color: '#219A63',
        name: 'Diner',
        // emoji: null,
        image: require('../../assets/images/dinner.png')
    },
    {
        type: 'drinks',
        color: '#5311A9',
        name: 'Borrel',
        // emoji: '🍷'
        image: require('../../assets/images/drinks.png')
    },
    {
        type: 'sport',
        color: '#219A63',
        name: 'Sport',
        // emoji: '🎊'
        // emoji: null,
        image: require('../../assets/images/sport.png')
    },
    // {
    //   type: 'theme',
    //   color: '#BB2035',
    //   name: 'thema',
    //   emoji: '🎭'
    // },
    {
        type: 'holiday',
        color: '#219A63',
        name: 'Vakantie',
        // emoji: null,
        image: require('../../assets/images/holiday.png')
    },
    {
        type: 'business',
        color: '#FFFFFF',
        name: 'Afspraak',
        // emoji: '💼'
        image: require('../../assets/images/meeting.png')
    },
    {
        type: 'party',
        color: '#219A63',
        name: 'Feest',
        // emoji: '🎊'
        image: require('../../assets/images/party.png')
    },
    // {
    //   type: 'premium',
    //   color: '#5311A9',
    //   name: 'premium',
    //   emoji: '⭐'
    // },
    {
        type: 'other',
        color: '#BB2035',
        name: 'Anders',
        // emoji: '❔',
        image: require('../../assets/images/other.png')
    }
];
var getType = function (type) {
    return types.find(function (t) { return t.type === type; });
};
var getSelectTypes = function () {
    return types.map(function (type) {
        return {
            value: type.name,
            label: type.name
        };
    });
};
export { getType, getSelectTypes };
export default types;
