import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { isEmpty } from 'lodash'

import LoaderWrapper from '@invie/common/dist/components/loader-wrapper'
import Emoji from '@invie/common/dist/components/emoji'
import DateComponent from '@invie/common/dist/components/date'
import Map from '@invie/common/dist/components/map'
import AdBox from '@invie/common/dist/components/ad-box'
import circle from '@invie/common/assets/images/circle.png'
import googleIcon from '@invie/common/assets/images/google.png'
import appleIcon from '@invie/common/assets/images/apple.png'
import './app.css'

import { Container, Row, Col } from '@invie/common/dist/elements/layout'
import {
  Background,
  Header,
  Box,
  Title,
  Subtitle,
  Text,
  Button
} from '@invie/common/dist/elements'

import { useStateValue } from '@invie/common/dist/utils/use-state'
import { getAd, getEvent } from '@invie/common/dist/utils/firebase'
import { getType } from '@invie/common/dist/utils/types'

import { useLocation, useNavigate, useParams } from 'react-router-dom'

const withRouter = (Component: any) => {
  function ComponentWithRouterProp(props: any) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return <Component {...props} router={{ location, navigate, params }} />
  }

  return ComponentWithRouterProp
}

const Presence: React.FC = (props: any) => {
  const [{ event, eventId, ad }, dispatch] = useStateValue()
  const [present] = useState(props.router.location.pathname === '/accepted')
  const type = event && getType(event.type)
  const emoji = type ? type.image : ''

  useEffect(() => {
    !isEmpty(event) &&
      isEmpty(ad) &&
      getAd(event.type).then((data: any) => {
        dispatch({
          type: 'REQUEST_AD_SUCCESS',
          ad: data
        })
      })
  }, [event])

  useEffect(() => {
    isEmpty(event) &&
      getEvent(eventId).then((data: any) => {
        if (data) {
          dispatch({
            type: 'REQUEST_EVENT_SUCCESS',
            event: data
          })
        }
      })
  }, [eventId])

  const calendarLinkGoogle = () => {
    window.location.href = `https://www.google.com/calendar/render?action=TEMPLATE&text=${
      event.title
    }&dates=${moment
      .unix(event.startTime.seconds)
      .utc()
      .format('YYYYMMDD[T]HHmmss[Z]')}%2F${moment
      .unix(event.endTime ? event.endTime.seconds : event.startTime.seconds)
      .utc()
      .format('YYYYMMDD[T]HHmmss[Z]')}&details=${event.message}&location=${
      event.address.streetName
    } ${event.address.streetNumber}, ${event.address.zip} ${
      event.address.city
    }, ${event.address.countryCode}`
    return null
  }

  const calendarLinkIcs = () => {
    //name of event in iCal
    const eventName = `${event.title}`

    //desc of event in iCal
    const eventDesc = `${event.title}`

    const location = `${event.address.streetName} ${event.address.streetNumber} ${event.address.zip} ${event.address.city}`

    //name of file to download as
    const fileName = 'invie-invite.ics'

    //start time of event in iCal
    const dateStart = `${moment
      .unix(event.startTime.seconds)
      .add(-2, 'hours')
      .format('YYYYMMDD[T]HHmmss[Z]')}`

    //end time of event in iCal
    const dateEnd =
      event.endTime && event.endTime.seconds !== 0
        ? `${moment
            .unix(event.endTime.seconds)
            .add(-2, 'hours')
            .format('YYYYMMDD[T]HHmmss[Z]')}`
        : `${moment
            .unix(event.startTime.seconds)
            .add(-1, 'hours')
            .format('YYYYMMDD[T]HHmmss[Z]')}`

    //helper functions
    const _zp = function (s: string) {
      return ('0' + s).slice(-2)
    }

    //iso date for ical formats
    const _isofix = function (d: string | Date) {
      const offset = ('0' + new Date().getTimezoneOffset() / 60).slice(-2)

      if (typeof d === 'string') {
        return d.replace(/\-/g, '') + 'T' + offset + '0000Z'
      } else {
        return (
          d.getFullYear() +
          _zp(`${Math.floor(d.getMonth() + 1)}`) +
          _zp(`${d.getDate()}`) +
          'T' +
          _zp(`${d.getHours()}`) +
          '0000Z'
        )
      }
    }

    //zero padding for data fixes
    const _save = function (fileURL: string) {
      const save = document.createElement('a')
      save.href = fileURL
      save.target = '_blank'
      save.download = fileName || 'unknown'

      const evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false
      })
      save.dispatchEvent(evt)
      ;(window.URL || window.webkitURL).revokeObjectURL(save.href)
    }

    const now = new Date()
    const icsLines = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'PRODID:-//INVIE//iCalHelper//EN',
      'METHOD:REQUEST',
      'BEGIN:VEVENT',
      'UID:event-' + now.getTime() + '@invie.app',
      'DTSTAMP:' + _isofix(now),
      'DTSTART:' + dateStart,
      'DTEND:' + dateEnd,
      'DESCRIPTION:' + eventName,
      'SUMMARY:' + eventDesc,
      'LAST-MODIFIED:' + _isofix(now),
      'LOCATION:' + location,
      'SEQUENCE:0',
      'END:VEVENT',
      'END:VCALENDAR'
    ]

    const dlurl = 'data:text/calendar;base64,' + btoa(icsLines.join('\r\n'))

    try {
      _save(dlurl)
    } catch (e) {
      console.log(e)
    }
  }
  const imageStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
    resizeMode: 'contain'
  }
  const circleStyle = {
    width: '16px'
  }
  const subTitleStyle = {
    paddingLeft: '20px',
    paddingTop: '0px',
    paddingBottom: '20px'
  }
  const textStyle = {
    fontSize: '14px',
    fontWeight: 'Bold',
    paddingTop: '10px'
  }
  const enterBtnStyle = {
    color: '#ffffff',
    borderRadius: '4px',
    backgroundColor: '#24A2EF',
    marginBottom: '10px'
  }
  const exitBtnStyle = {
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    color: '#24A2EF',
    marginBottom: '10px'
  }
  const containerStyle = {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    padding: '30px'
  }

  if (isEmpty(event)) return <LoaderWrapper />

  return (
    <Background color='primary' style={{ padding: '40px' }}>
      <Container fullHeight padding style={containerStyle}>
        <Header>
          <Row middle='xs' style={{ marginTop: '20px' }}>
            <Col xs={12} className='top-emoji'>
              {/* <Emoji emoji={emoji} /> */}
              {emoji ? <img src={emoji} style={{ width: '60px' }} /> : null}
            </Col>
            <Col xs={12}>
              <Title>
                {present ? 'Leuk dat je komt!' : 'Jammer dat je niet kan komen'}
              </Title>
            </Col>
            <Col xs={2}>
              {/*  <Emoji emoji={emoji} /> */}
              {emoji ? <img src={emoji} style={{ width: '60px' }} /> : null}
            </Col>
            <Text color='dark'>
              Je kunt je aanwezigheid bijwerken via je persoonlijke link.
            </Text>
          </Row>
        </Header>
        {/* <Header>
          <Row middle='xs'>
            <Col xs={12}>
              <Title color='light'>
                {present ? 'Leuk dat je komt!' : 'Jammer dat je niet kan komen'}
              </Title>
              <Text color='light'>
                Je kunt je aanwezigheid bijwerken via je persoonlijke link.
              </Text>
            </Col>
          </Row>
        </Header> */}

        {present && (
          <React.Fragment>
            {event.eventImage !== '' && (
              <Row middle='xs'>
                <Col xs={12}>
                  <img src={event.eventImage} style={imageStyle} />
                </Col>
              </Row>
            )}
            <Row middle='xs' style={{ marginTop: '5px' }}>
              <Col xs={12}>
                <Title>{event.title}</Title>
              </Col>
              {/* <Col xs={2} className='left-emoji'>
                <Emoji emoji={emoji} />
              </Col> */}
            </Row>

            {event?.organizer ? (
              <Col xs={9}>
                <Subtitle
                  style={{
                    marginBottom: '1rem',
                    fontWeight: '500',
                    color: event.template.textColor,
                    marginLeft: -10
                  }}
                >
                  <FormattedMessage id='descriptions.organizedBy' />:{' '}
                  {event?.organizer}
                </Subtitle>
              </Col>
            ) : (
              <></>
            )}

            <Row>
              <Col xs={12}>
                <Text>{event.message}</Text>
              </Col>
            </Row>
            {/* <Row middle='xs'>
              <Col xs={2}>
                <Emoji emoji={emoji} small />
              </Col>
              <Col xs={10}>
                <Subtitle color='primary'>{event.title}</Subtitle>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box color='light'>
                  <DateComponent
                    startTime={moment.unix(event.startTime.seconds)}
                    endTime={
                      event.endTime && moment.unix(event.endTime.seconds)
                    }
                  />
                </Box>
              </Col>
            </Row> */}

            {/* <Box padding={false} color='light'>
              <Row middle='xs'>
                <Col xs={4}>
                  <Map
                    coordinates={[
                      event.location.longitude,
                      event.location.latitude
                    ]}
                    small
                    zoom={15}
                  />
                </Col>
                <Col xs={8}>
                  <Subtitle color='dark'>
                    {event.address.streetName} {event.address.streetNumber}
                  </Subtitle>
                  <Text color='dark' margin={false}>
                    {event.address.city}
                  </Text>
                </Col>
              </Row>
            </Box> */}
            <Row>
              <Col xs={12}>
                {moment.unix(event.startTime.seconds).format('DD MMM, YYYY') ===
                  moment.unix(event.endTime.seconds).format('DD MMM, YYYY') && (
                  <Row>
                    <Col xs={12}>
                      <Text style={{ fontSize: '14px' }}>
                        <img src={circle} style={circleStyle} />
                        &nbsp;&nbsp;Begintijd
                      </Text>
                      <Subtitle style={subTitleStyle}>
                        {moment
                          .unix(event.startTime.seconds)
                          .format('DD MMM, YYYY')}
                        &nbsp;-&nbsp;
                        {moment
                          .unix(event.startTime.seconds)
                          .format('h:mm:ss A')}
                        &nbsp;-&nbsp;
                        {moment.unix(event.endTime.seconds).format('h:mm:ss A')}
                      </Subtitle>
                    </Col>
                  </Row>
                )}
                {moment.unix(event.startTime.seconds).format('DD MMM, YYYY') !==
                  moment.unix(event.endTime.seconds).format('DD MMM, YYYY') && (
                  <>
                    <Row>
                      <Col xs={12}>
                        <Text style={{ fontSize: '14px' }}>
                          <img src={circle} style={circleStyle} />
                          &nbsp;&nbsp;Begintijd
                        </Text>
                        <Subtitle style={subTitleStyle}>
                          {moment
                            .unix(event.startTime.seconds)
                            .format('DD MMM, YYYY')}
                          &nbsp;-&nbsp;
                          {moment
                            .unix(event.startTime.seconds)
                            .format('h:mm:ss A')}
                        </Subtitle>
                        <Text style={{ fontSize: '14px' }}>
                          <img src={circle} style={circleStyle} />
                          &nbsp;&nbsp;Eindtijd
                        </Text>
                        <Subtitle style={subTitleStyle}>
                          {moment
                            .unix(event.endTime.seconds)
                            .format('DD MMM, YYYY')}
                          &nbsp;-&nbsp;
                          {moment
                            .unix(event.endTime.seconds)
                            .format('h:mm:ss A')}
                        </Subtitle>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Text style={textStyle}>&nbsp;&nbsp;Locatie</Text>
                <Box>
                  <Subtitle>
                    {event.address.streetName + ' '}
                    {event.address.streetNumber + ', '}
                    {event.address.city}
                    {/* {event.address.zip} */}
                  </Subtitle>
                  {/* <Date
                    startTime={moment.unix(event.startTime.seconds).format()}
                    endTime={
                      event.endTime && moment.unix(event.endTime.seconds).format()
                    }
                  /> */}
                </Box>
                <Box padding={false} style={{ overflow: 'hidden' }}>
                  <Row middle='xs'>
                    <Col xs={12}>
                      <Map
                        coordinates={[
                          event.location.longitude,
                          event.location.latitude
                        ]}
                        zoom={15}
                      />
                    </Col>
                    {/* <Col xs={8}>
                      <Subtitle>
                        {event.address.streetName} {event.address.streetNumber}
                      </Subtitle>
                      <Text margin={false}>{event.address.city}</Text>
                    </Col> */}
                  </Row>
                </Box>
              </Col>
            </Row>
          </React.Fragment>
        )}
        {/* <Row>
          <Col xs={12}>
            {present && (
              <>
                <Button
                  color='light'
                  outline
                  block
                  onClick={() => calendarLinkGoogle()}
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                >
                  <FormattedMessage id='buttons.addToCalendarGoogle' />
                </Button>
                <Button
                  color='light'
                  outline
                  block
                  onClick={() => calendarLinkIcs()}
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                >
                  <FormattedMessage id='buttons.addToCalendarIcs' />
                </Button>
              </>
            )}
            <Text color='light'>
              {present
                ? 'Kan je toch niet komen? Open dan opnieuw deze pagina.'
                : 'Kan je toch komen? Open dan opnieuw deze pagina.'}
            </Text>
            {!isEmpty(ad) && (
              <AdBox
                background={ad.background}
                color={ad.color}
                text={ad.text}
                buttonText={ad.buttonText}
                imageUrl={ad.imageUrl}
                onClick={() => window.open(ad.adUrl)}
              />
            )}
          </Col>
        </Row> */}
        <Row style={{ marginTop: '30px' }}>
          <Col xs={12} md={6}>
            <Col xs={12} md={12} className='google-calender-btn'>
              <Button
                color='light'
                outline
                block
                onClick={() => calendarLinkGoogle()}
                style={enterBtnStyle}
              >
                <img src={googleIcon} style={circleStyle} />
                &nbsp;
                <FormattedMessage id='buttons.addToCalendarGoogle' />
              </Button>
            </Col>
          </Col>
          <Col xs={12} md={6}>
            <Col xs={12} md={12} className='google-calender-btn'>
              <Button
                color='light'
                outline
                block
                onClick={() => calendarLinkIcs()}
                style={exitBtnStyle}
              >
                <img src={appleIcon} style={circleStyle} />
                &nbsp;
                <FormattedMessage id='buttons.addToCalendarIcs' />
              </Button>
            </Col>
          </Col>
          <Col xs={12}>
            <Text color='light'>
              {present
                ? 'Kan je toch niet komen? Open dan opnieuw deze pagina.'
                : 'Kan je toch komen? Open dan opnieuw deze pagina.'}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {!isEmpty(ad) && (
              <AdBox
                background={ad.background}
                color={ad.color}
                text={ad.text}
                buttonText={ad.buttonText}
                imageUrl={ad.imageUrl}
                onClick={() => window.open(ad.adUrl)}
              />
            )}
          </Col>
        </Row>
      </Container>
    </Background>
  )
}

export default withRouter(Presence)
