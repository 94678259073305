export const initialState = {
  publicEvent: {},
  event: {},
  eventId: localStorage.getItem('eventId'),
  ad: {}
}

const publicEventReducer = (
  state: object,
  action: { type: string; event: any }
) => {
  switch (action.type) {
    case 'REQUEST_PUBLIC_EVENT_SUCCESS':
      return {
        ...state,
        ...action.event
      }

    default:
      return state
  }
}

const eventReducer = (state: object, action: { type: string; event: any }) => {
  switch (action.type) {
    case 'REQUEST_EVENT_SUCCESS':
      localStorage.setItem('eventId', action.event.eventId)

      return {
        ...state,
        ...action.event
      }

    default:
      return state
  }
}

const adReducer = (state: object, action: { type: string; ad: any }) => {
  switch (action.type) {
    case 'REQUEST_AD_SUCCESS':
      return {
        ...state,
        ...action.ad
      }

    default:
      return state
  }
}

export const mainReducer = ({ publicEvent, event, ad }: any, action: any) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(action)
  }

  return {
    publicEvent: publicEventReducer(publicEvent, action),
    event: eventReducer(event, action),
    ad: adReducer(ad, action)
  }
}
