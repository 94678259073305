import React from 'react';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import flatten from 'flat';
import { AuthProvider } from '../utils/use-auth';
import { StateProvider } from '../utils/use-state';
import GlobalStyle, { theme } from '../utils/theme';
var Wrapper = function (props) {
    return (React.createElement(AuthProvider, null,
        React.createElement(StateProvider, { initialState: props.initialState, mainReducer: props.mainReducer },
            React.createElement(IntlProvider, { locale: 'nl', defaultLocale: 'nl', messages: flatten(props.messages) },
                React.createElement(ThemeProvider, { theme: theme },
                    React.createElement(React.Fragment, null,
                        React.createElement(GlobalStyle, null),
                        props.children))))));
};
export default Wrapper;
