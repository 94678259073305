import React from 'react';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import mapboxgl from "mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
// @ts-ignore
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
var MapBox = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiaW52aWUtMjAyMSIsImEiOiJja3dzeXZwOTUxYjg5MndxbzZ2MzBzdm9mIn0.nQEaB2M1phsG12ihgAFdFA',
    interactive: false,
    attributionControl: false
});
var Map = function (props) {
    return (React.createElement(MapBox, { style: 'mapbox://styles/mapbox/streets-v10', containerStyle: {
            height: props.small ? '70px' : '200px',
            width: '100%'
        }, center: props.coordinates, zoom: [props.zoom ? props.zoom : 16] },
        React.createElement(Layer, { type: 'symbol', layout: {
                'icon-image': 'marker-15',
                'icon-size': 2
            } },
            React.createElement(Feature, { coordinates: props.coordinates }))));
};
export default Map;
