import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import App from '../containers/app'
import Event from '../containers/event'
import Presence from '../containers/presence'
import Wrapper from '@invie/common/dist/components/wrapper'

import messages from '../translations/nl.json'
import { initialState, mainReducer } from '../utils/reducer'

const createRoutes = () => (
  <Wrapper
    messages={messages}
    initialState={initialState}
    mainReducer={mainReducer}
  >
    <Router>
      <Routes>
        <Route path='/event' element={<Event />} />
        <Route path='/accepted' element={<Presence />} />
        <Route path='/declined' element={<Presence />} />
        <Route path='/:eventId' element={<App />} />
      </Routes>
    </Router>
  </Wrapper>
)

export default createRoutes
