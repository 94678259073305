var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Grid as FGrid, Col as FCol, Row as FRow } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
var Container = styled(FGrid)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  display: flex;\n  flex-direction: column;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), function (props) {
    return props.fullHeight &&
        "\n    min-height: 100%;\n  ";
}, function (props) {
    return props.padding &&
        "\n    padding-top: 1rem;\n    padding-bottom: 1rem;\n  ";
}, function (props) {
    return props.start &&
        "\n    justify-content: flex-start;\n  ";
}, function (props) {
    return props.center &&
        "\n    justify-content: center;\n  ";
}, function (props) {
    return props.end &&
        "\n    justify-content: flex-end;\n  ";
}, function (props) {
    return props.around &&
        "\n    justify-content: space-around;\n  ";
}, function (props) {
    return props.between &&
        "\n    justify-content: space-between;\n  ";
});
var Row = styled(FRow)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) {
    return props.padding &&
        "\n    margin-top: 0.5rem;\n    margin-bottom: 0.5rem;\n  ";
});
var Col = styled(FCol)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export { Container, Row, Col };
var templateObject_1, templateObject_2, templateObject_3;
